import React from "react";
import "./Shareholders.css";

function Shartholders() {
  return (
    <div className="shareholder-main-container">
      <h1>No of shareholders (Resident & Non-Resident) & % of shareholding</h1>

      {/* Table 01 */}
      <div className="shareholder-sub-container">
        <h3>Voting Shareholders</h3>
        <div className="shareholder-table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  colSpan={3}
                  className="shareholder-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  2024
                </th>
                <th></th>
                <th
                  colSpan={3}
                  className="shareholder-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  2023
                </th>
              </tr>
              <tr className="shareholder-table-main-header">
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Shares Held
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  No. of Shareholders
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  Total Shareholding
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  %
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  No. of Shareholders
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1000"
                  data-aos-once="true"
                >
                  Total Shareholding
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  %
                </th>
              </tr>
            </thead>

            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>Resident</td>
                <td>4,103</td>
                <td>280,968,178</td>
                <td>95.35</td>
                <td></td>
                <td>3,786</td>
                <td>278,417,448</td>
                <td>95.48</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>Non-Resident</td>
                <td>32</td>
                <td>10,631,822</td>
                <td>3.65</td>
                <td></td>
                <td>36</td>
                <td>13,182,552</td>
                <td>4.52</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="900" data-aos-once="true">
                <td>Total</td>
                <td>4,135</td>
                <td>291,600,000</td>
                <td>100.00</td>
                <td></td>
                <td>3,822</td>
                <td>291,600,000</td>
                <td>100.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Table 02 */}
      <div className="shareholder-sub-container">
        <h3>Non-Voting Shareholders</h3>
        <div className="shareholder-table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  colSpan={3}
                  className="shareholder-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  2024
                </th>
                <th></th>
                <th
                  colSpan={3}
                  className="shareholder-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  2023
                </th>
              </tr>
              <tr className="shareholder-table-main-header">
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Shares Held
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  No. of Shareholders
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  Total Shareholding
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  %
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  No. of Shareholders
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1000"
                  data-aos-once="true"
                >
                  Total Shareholding
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  %
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>Resident</td>
                <td>4,099</td>
                <td>84,311,504</td>
                <td>96.38</td>
                <td></td>
                <td>3,944</td>
                <td>84,088,533</td>
                <td>96.12</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>Non-Resident</td>
                <td>62</td>
                <td>3,168,496</td>
                <td>3.62</td>
                <td></td>
                <td>62</td>
                <td>3,391,467</td>
                <td>3.88</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="900" data-aos-once="true">
                <td>Total</td>
                <td>4,161</td>
                <td>87,480,000</td>
                <td>100.00</td>
                <td></td>
                <td>4,006</td>
                <td>87,480,000</td>
                <td>100.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Shartholders;
