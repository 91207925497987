import React, { useEffect, useState } from "react";
import "./LargestShareholders.css";
import { IoSearchOutline } from "react-icons/io5";
import TableData01 from "./TableData01";
import TableData02 from "./TableData02";

function LargestShareholders() {
  const [data01, setData01] = useState([]);
  const [searchTexts01, setSearchText01] = useState("");
  const [searchedData, setSearchData] = useState([]);

  const [data02, setData02] = useState([]);
  const [searchTexts02, setSearchText02] = useState("");
  const [searchedData02, setSearchData02] = useState([]);

  useEffect(() => {
    setData01(TableData01);
    setSearchData(TableData01);
    setData02(TableData02);
    setSearchData02(TableData02);
  }, []);

  useEffect(() => {
    if (searchTexts01 !== null && searchTexts01 !== "") {
      const filteredData01 = data01.filter((item) =>
        item.name.toLowerCase().includes(searchTexts01.toLowerCase())
      );
      setSearchData(filteredData01);
    }
  }, [searchTexts01]);

  useEffect(() => {
    if (searchTexts02 !== null && searchTexts02 !== "") {
      const filteredData02 = data02.filter((item) =>
        item.name.toLowerCase().includes(searchTexts02.toLowerCase())
      );
      setSearchData02(filteredData02);
    }
  }, [searchTexts02]);

  return (
    <div className="l-shareholder-main-container">
      <h1>20 Largest Shareholders for Voting & Non-Voting Shares</h1>

      {/* Table one */}
      <div className="l-shareholder-sub-container">
        <div className="l-shareholder-sub-container-header">
          <div>
            <h3
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              Twenty Largest Shareholders - Ordinary Shares
            </h3>
          </div>
          <div
            className="l-shareholder-sub-container-header-search-bar"
            data-aos="fade-left"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <IoSearchOutline size={18} />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchText01(e.target.value)}
            />
          </div>
        </div>
        <div className="l-shareholder-sub-container-table">
          <table>
            <thead>
              <tr>
                <th colSpan={3}></th>
                <th
                  colSpan={2}
                  className="l-shareholder-sub-container-table-main-th"
                  data-aos="fade-down"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  2024
                </th>
                <th></th>
                <th
                  colSpan={2}
                  className="l-shareholder-sub-container-table-main-th"
                  data-aos="fade-down"
                  data-aos-duration="500"
                  data-aos-delay="500"
                  data-aos-once="true"
                >
                  2023
                </th>
              </tr>
              <tr className="l-shareholder-sub-container-table-sub-th">
                <th
                  data-aos="fade-right"
                  data-aos-duration="300"
                  data-aos-delay="1000"
                  data-aos-once="true"
                >
                  No.
                </th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  Name
                </th>
                <th></th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1400"
                  data-aos-once="true"
                >
                  No. of shares
                </th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1600"
                  data-aos-once="true"
                >
                  %
                </th>
                <th></th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1800"
                  data-aos-once="true"
                >
                  No. of shares
                </th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="2000"
                  data-aos-once="true"
                >
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              {searchedData.length > 0 ? (
                searchedData.map((item, index) =>
                  item.type ? (
                    <tr
                      className="l-shareholder-sub-container-table-last-row"
                      data-aos={index % 2 == 0 ? "fade-left" : "fade-right"}
                      data-aos-duration="500"
                      data-aos-once="true"
                    >
                      <td colSpan={2}>{item.name}</td>
                      <td></td>
                      <td>{item.shares2024}</td>
                      <td>{item.percentage2024}</td>
                      <td></td>
                      <td>{item.shares2023}</td>
                      <td>{item.percentage2023}</td>
                    </tr>
                  ) : (
                    <tr
                      data-aos={index % 2 == 0 ? "fade-left" : "fade-right"}
                      data-aos-duration="500"
                      data-aos-once="true"
                    >
                      <td>{index < 9 ? "0" + (index + 1) : index + 1}</td>
                      <td>{item.name}</td>
                      <td></td>
                      <td>{item.shares2024}</td>
                      <td>{item.percentage2024}</td>
                      <td></td>
                      <td>{item.shares2023}</td>
                      <td>{item.percentage2023}</td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={8}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Table Two */}
      <div className="l-shareholder-sub-container">
        <div className="l-shareholder-sub-container-header">
          <div>
            <h3>Twenty Largest Shareholders - Non Voting (Class-x) Shares</h3>
          </div>
          <div className="l-shareholder-sub-container-header-search-bar">
            <IoSearchOutline size={18} />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchText02(e.target.value)}
            />
          </div>
        </div>
        <div className="l-shareholder-sub-container-table">
          <table>
            <thead>
              <tr>
                <th colSpan={3}></th>
                <th
                  colSpan={2}
                  className="l-shareholder-sub-container-table-main-th"
                  data-aos="fade-down"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  2024
                </th>
                <th></th>
                <th
                  colSpan={2}
                  className="l-shareholder-sub-container-table-main-th"
                  data-aos="fade-down"
                  data-aos-duration="500"
                  data-aos-delay="500"
                  data-aos-once="true"
                >
                  2023
                </th>
              </tr>
              <tr className="l-shareholder-sub-container-table-sub-th">
                <th
                  data-aos="fade-right"
                  data-aos-duration="300"
                  data-aos-delay="1000"
                  data-aos-once="true"
                >
                  No.
                </th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1200"
                  data-aos-once="true"
                >
                  Name
                </th>
                <th></th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1400"
                  data-aos-once="true"
                >
                  No. of shares
                </th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1600"
                  data-aos-once="true"
                >
                  %
                </th>
                <th></th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="1800"
                  data-aos-once="true"
                >
                  No. of shares
                </th>
                <th
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-delay="2000"
                  data-aos-once="true"
                >
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              {searchedData02.length > 0 ? (
                searchedData02.map((item, index) =>
                  item.type ? (
                    <tr
                      className="l-shareholder-sub-container-table-last-row"
                      data-aos={index % 2 == 0 ? "fade-left" : "fade-right"}
                      data-aos-duration="500"
                      data-aos-once="true"
                    >
                      <td colSpan={2}>{item.name}</td>
                      <td></td>
                      <td>{item.shares2024}</td>
                      <td>{item.percentage2024}</td>
                      <td></td>
                      <td>{item.shares2023}</td>
                      <td>{item.percentage2023}</td>
                    </tr>
                  ) : (
                    <tr
                      data-aos={index % 2 == 0 ? "fade-left" : "fade-right"}
                      data-aos-duration="500"
                      data-aos-once="true"
                    >
                      <td>{index < 9 ? "0" + (index + 1) : index + 1}</td>
                      <td>{item.name}</td>
                      <td></td>
                      <td>{item.shares2024}</td>
                      <td>{item.percentage2024}</td>
                      <td></td>
                      <td>{item.shares2023}</td>
                      <td>{item.percentage2023}</td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={8}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LargestShareholders;
