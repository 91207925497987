const TableData01 = [
    {
        name:"Paints & General Industries Limited",
        shares2024:"155,441,396",
        percentage2024:"53.31",
        shares2023:"155,441,396",
        percentage2023:"53.31",
    },
    {
        name:"Employee's Provident Fund",
        shares2024:"26,417,892",
        percentage2024:"9.06",
        shares2023:"26,417,892",
        percentage2023:"9.06",
    },
    {
        name:"Sampath Bank PLC / Chacra Capital Holdings (Private) Limited",
        shares2024:"13,104,960",
        percentage2024:"4.49",
        shares2023:"13,104,960",
        percentage2023:"4.49",
    },
    {
        name:"Citibank New York S/A Norges Bank Account 2",
        shares2024:"9,975,561",
        percentage2024:"3.42",
        shares2023:"9,875,561",
        percentage2023:"3.39",
    },
    {
        name:"Employees Trust Fund Board",
        shares2024:"5,320,236",
        percentage2024:"1.82",
        shares2023:"2,333,038",
        percentage2023:"0.80",
    },
    {
        name:"Finco Holdings (Private) Limited",
        shares2024:"4,013,235",
        percentage2024:"1.38",
        shares2023:"-",
        percentage2023:"-",
    },
    {
        name:"Sri Lanka Insurance Corporation Limited - Life Fund",
        shares2024:"3,104,855",
        percentage2024:"1.06",
        shares2023:"9,245,423",
        percentage2023:"3.17",
    },
    {
        name:"Mr. E.D. Pieris",
        shares2024:"3,000,022",
        percentage2024:"1.03",
        shares2023:"-",
        percentage2023:"-",
    },
    {
        name:"Deutsche Bank AG as Trustee To Assetline Income Plus Growth Fund",
        shares2024:"3,000,000",
        percentage2024:"1.03",
        shares2023:"-",
        percentage2023:"-",
    },
    {
        name:"Sri Lanka Insurance Corporation Limited - General Fund",
        shares2024:"2,205,898",
        percentage2024:"0.76",
        shares2023:"2,345,898",
        percentage2023:"0.80",
    },
    {
        name:"Mrs. J.N.Ambani",
        shares2024:"2,122,966",
        percentage2024:"0.73",
        shares2023:"1,983,000",
        percentage2023:"0.68",
    },
    {
        name:"Dr. H.R.Wikremesinghe & Mr. V.K.Wikremesinghe",
        shares2024:"1,752,242",
        percentage2024:"0.61",
        shares2023:"1,767,316",
        percentage2023:"0.61",
    },
    {
        name:"S.K.Wikremesinghe Trust (Guarantee) Limited",
        shares2024:"1,360,000",
        percentage2024:"0.47",
        shares2023:"1,360,000",
        percentage2023:"4.49",
    },
    {
        name:"Colombo Fort Investments PLC",
        shares2024:"1,220,000",
        percentage2024:"0.42",
        shares2023:"1,220,000",
        percentage2023:"0.42",
    },
    {
        name:"Mrs. L.K.Goonawardena",
        shares2024:"1,205,120",
        percentage2024:"0.41",
        shares2023:"1,205,120",
        percentage2023:"0.41",
    },
    {
        name:"Miss. N.K.R.H. De Silva",
        shares2024:"1,135,700",
        percentage2024:"0.39",
        shares2023:"1,135,700",
        percentage2023:"0.39",
    },
    {
        name:"Seylan Bank PLC/ Andaradeniya Estate (Private) Limited",
        shares2024:"1,088,300",
        percentage2024:"0.37",
        shares2023:"1,088,300",
        percentage2023:"0.37",
    },
    {
        name:"National Savings Bank",
        shares2024:"1,005,257",
        percentage2024:"0.34",
        shares2023:"1,005,257",
        percentage2023:"0.34",
    },
    {
        name:"Mr. R.K. Modder",
        shares2024:"862,500",
        percentage2024:"0.30",
        shares2023:"-",
        percentage2023:"-",
    },
    {
        name:"Mr. A.K. Wikramanayake",
        shares2024:"850,000",
        percentage2024:"0.29",
        shares2023:"850,000",
        percentage2023:"0.29",
    },
    {
        name:"Total",
        type:"totle",
        shares2024:"238,186,140",
        percentage2024:"81.68",
        shares2023:"230,378,861",
        percentage2023:"79.01",
    }
]


export default TableData01;