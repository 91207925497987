import React, { useState } from "react";
import "./TradingInformation.css";

function TradingInformation() {
  return (
    <div className="trading-information-main-container">
      {/* Table 01 */}
      <div className="trading-information-sub-container">
        <h3>Share Trading information</h3>
        <div className="trading-information-table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th
                  colSpan={2}
                  className="trading-information-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  Ordinary Shares
                </th>
                <th></th>
                <th
                  colSpan={2}
                  className="trading-information-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Non-Voting Shares
                </th>
              </tr>
              <tr className="trading-information-table-main-header">
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Shares Held
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  2024
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  2023
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="400"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  2024
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  2023
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>No. of shares traded</td>
                <td></td>
                <td>53,311,192</td>
                <td>85,988,194</td>
                <td></td>
                <td>46,967,448</td>
                <td>114,793,429</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>No. of transactions</td>
                <td></td>
                <td>14,492</td>
                <td>26,322</td>
                <td></td>
                <td>18,901</td>
                <td>41,376</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>Value of shares traded (Rs)</td>
                <td></td>
                <td>3,673,773,455</td>
                <td>6,692,960,603</td>
                <td></td>
                <td>2,471,464,783</td>
                <td>6,334,207,846</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Table 02 */}
      <div className="trading-information-sub-container">
        <h3>Directors’ Shareholding</h3>
        <div className="trading-information-table-container">
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th
                  colSpan={2}
                  className="trading-information-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  Ordinary Shares
                </th>
                <th></th>
                <th
                  colSpan={2}
                  className="trading-information-table-year-header"
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Non-Voting Shares
                </th>
              </tr>
              <tr className="trading-information-table-main-header">
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  Shares Held
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  31-03-2024
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  31-03-2023
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="400"
                  data-aos-once="true"
                ></th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  31-03-2024
                </th>
                <th
                  data-aos="fade-in"
                  data-aos-duration="300"
                  data-aos-delay="800"
                  data-aos-once="true"
                >
                  31-03-2023
                </th>
              </tr>
            </thead>
            <tbody
              data-aos="fade-in"
              data-aos-duration="700"
              data-aos-once="true"
            >
              <tr data-aos="fade-up" data-aos-delay="700" data-aos-once="true">
                <td>Mr. S.H. Amarasekera - Chairman</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>46,967,448</td>
                <td>114,793,429</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="800" data-aos-once="true">
                <td>Mr. R.S. Captain</td>
                <td></td>
                <td>320</td>
                <td>320</td>
                <td></td>
                <td>556</td>
                <td>556</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="900" data-aos-once="true">
                <td>Mr. S.M. Enderby</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1000" data-aos-once="true">
                <td>Mr. J.R. Gunaratne</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1100" data-aos-once="true">
                <td>Mr. D.T.S.H. Mudalige</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1200" data-aos-once="true">
                <td>Mr. M.P. Jayawardena</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1300" data-aos-once="true">
                <td>Mr. M.P. Jayawardena</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>5,000</td>
                <td>5,000</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1400" data-aos-once="true">
                <td>Mr. P.R. Saldin</td>
                <td></td>
                <td>-</td>
                <td>-</td>
                <td></td>
                <td>2,000</td>
                <td>2,000</td>
              </tr>
              <tr data-aos="fade-up" data-aos-delay="1500" data-aos-once="true">
                <td>Mr. K.D. Senewiratne</td>
                <td></td>
                <td>320</td>
                <td>320</td>
                <td></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr
                className="trading-information-table-02-last-row"
                data-aos="fade-up"
                data-aos-delay="1600"
                data-aos-once="true"
              >
                <td>Total</td>
                <td></td>
                <td>320</td>
                <td>320</td>
                <td></td>
                <td>7,556</td>
                <td>7,556</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TradingInformation;
