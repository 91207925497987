import React from 'react'
import InvestorInformationMainBanner from './InvestorInformationMainBanner'
import Shartholders from './Shareholders'
import LargestShareholders from './LargestShareholders'
import TradingInformation from './TradingInformation'

function InvestorInformationHome() {
  return (
    <div>
      <InvestorInformationMainBanner />
      <Shartholders />
      <LargestShareholders />
      <TradingInformation/>
    </div>
  )
}

export default InvestorInformationHome
