import React,{ useEffect } from 'react'
import './InvestorInformationMainBanner.css'
import InvestorInformationImage from "../../images/investorInformationMainBanner.webp"
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function InvestorInformationMainBanner() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  return (
    <div>
      <div>
        <div className="investor-information">
          <img className="investor-information-home-image" alt="" src={InvestorInformationImage} />
          <div className="investor-information-image-text-box">
            <div className="investor-information-image-background-text">
              <h1 className="investor-information-image-topic">
                Investor Information
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
