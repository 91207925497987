import React from 'react'
import InverstorInformation from '../templates/InvestorInformation/InvestorInformationHome'

function InvestorInformation() {
  return (
    <div>
      <InverstorInformation />
    </div>
  )
}

export default InvestorInformation
